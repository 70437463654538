<template>
  <div class="w-full">
    <div class="flex-shrink-0 p-4 border-b border-gray-100 flex flex-col gap-4">
      <fw-heading>Versões do documento</fw-heading>
      <div class="flex gap-3">
        <fw-button
          v-if="currentVersion.state != 'draft'"
          class="flex-1"
          type="xlight"
          @click.native="$emit('snapshot', currentVersion.key)"
        >
          {{ 'Criar cópia desta versão' }}
        </fw-button>
        <fw-button v-if="currentVersion.state == 'draft'" class="flex-1" type="primary" @click.native="publish()">
          {{ 'Publicar' }}
        </fw-button>
      </div>
    </div>
    <div v-if="!loading" class="px-4 flex flex-col gap-2 pt-4">
      <RecordVersion
        v-for="version in versions"
        :key="version.key"
        :active="currentVersion && version.key == currentVersion.key"
        :version="version"
        @click.native="changeVersion(version)"
      />
    </div>
    <div v-else class="px-4 flex flex-col gap-2">
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
    </div>
  </div>
</template>
<script>
import ServicePages from '../../services/ServicePages'
import RecordVersion from '../records/RecordVersion.vue'
export default {
  name: 'PanelSideVersions',
  components: {
    RecordVersion
  },
  props: {
    currentVersion: {
      type: Object
    },
    pageKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      versions: []
    }
  },
  created() {
    console.log('PanelSideVersions created')
    this.loadPageVersions()
  },
  methods: {
    publish() {
      this.$emit('publish', this.currentVersion.key)
      setTimeout(() => {
        this.loadPageVersions()
      }, 1000)
    },
    createVersion() {
      this.$emit('create-version')
    },
    changeVersion(version) {
      console.log('PanelSideVersions.changeVersion', version)
      this.$emit('change-version', version)
    },
    loadPageVersions() {
      this.loading = true
      ServicePages.getAllPageVersions(this.pageKey)
        .then(result => {
          console.log('PanelSideVersions.loadPageVersions', result)
          this.versions = result.versions
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
